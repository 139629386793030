import React from 'react';
import './SupportSection.css'
import support from '../../assets/images/supportPage.png';

function SupportSection() {
    return (
        <section className="support">
        <div className="support-content">
            <div className="support-text">
            <h2>Join 100+ startups supporting our mission</h2>
            <ul>
                <li><span>1</span> Enter job parameters.</li>
                <li><span>2</span> Review top candidate profiles.</li>
                <li><span>3</span> Review and automatically send interview invites.</li>
                <li><span>4</span> Get notified when candidates book interviews.</li>
            </ul>
            <button className="cta-button">Get started</button>
            </div>
            <div className="support-image">
            <img src={support} alt="Supporting Startups" />
            </div>
        </div>
        </section>
    );
}

export default SupportSection;
