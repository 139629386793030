import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/navigateBackHeader';

function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await fetch('https://resume.innovatorsoftomorrow.org/api/companies/auth/login', {
        method: 'POST',
//	mode: 'no-cors',  
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('authToken', data.token);
        console.log('Login successful:', data);
        navigate('/form');
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Login failed. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
      console.error('Login error:', error);
    }
  };

  return (
    <div>
      <Header />
      <div className="login-page">
        <motion.div 
          className="login-container"
          initial={{ opacity: 0, x: '100%' }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
        >
          <h2>Log in to your account</h2>
          <p>Welcome back! Please enter your details.</p>
          {error && <p className="error-message">{error}</p>}
          <form onSubmit={handleLogin}>
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label>Password</label>
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="remember-forgot">
              <a href="/forgot-password">Forgot password?</a>
            </div>
            <button type="submit" className="login-button">Sign in</button>
            <button type="button" className="google-login-button">
              <img src="https://developers.google.com/identity/images/g-logo.png" alt="Google Logo" />
              Sign in with Google
            </button>
            <p className="signup-link">
              Don't have an account? <a href="/signup">Sign up</a>
            </p>
          </form>
        </motion.div>
      </div>
    </div>
  );
}

export default LoginPage;
