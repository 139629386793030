// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    text-align: left;
    padding: 20px;
    background-color: #fff6e5; /* Adjusted color to match your header */
    font-family: 'Roboto', sans-serif;
    position: relative; /* Change to relative to make it non-fixed */
    width: 100%;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow to the footer */
    /* margin-top: 20px; Add margin to separate it from the content above */
  }
  
  .footer-content {
    display: flex;
    justify-content: left;
    align-items: center;
  }
  
  .footer-logo {
    height: 40px; /* Adjust size as needed */
    margin-right: 10px;
  }
  
  .footer p {
    margin: 0;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,yBAAyB,EAAE,wCAAwC;IACnE,iCAAiC;IACjC,kBAAkB,EAAE,4CAA4C;IAChE,WAAW;IACX,yCAAyC,EAAE,uCAAuC;IAClF,uEAAuE;EACzE;;EAEA;IACE,aAAa;IACb,qBAAqB;IACrB,mBAAmB;EACrB;;EAEA;IACE,YAAY,EAAE,0BAA0B;IACxC,kBAAkB;EACpB;;EAEA;IACE,SAAS;EACX","sourcesContent":[".footer {\r\n    text-align: left;\r\n    padding: 20px;\r\n    background-color: #fff6e5; /* Adjusted color to match your header */\r\n    font-family: 'Roboto', sans-serif;\r\n    position: relative; /* Change to relative to make it non-fixed */\r\n    width: 100%;\r\n    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow to the footer */\r\n    /* margin-top: 20px; Add margin to separate it from the content above */\r\n  }\r\n  \r\n  .footer-content {\r\n    display: flex;\r\n    justify-content: left;\r\n    align-items: center;\r\n  }\r\n  \r\n  .footer-logo {\r\n    height: 40px; /* Adjust size as needed */\r\n    margin-right: 10px;\r\n  }\r\n  \r\n  .footer p {\r\n    margin: 0;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
