import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage/HomePage';
import LoginPage from './pages/LoginPage/LoginPage';
import FormPage from './pages/FormPage/FormPage';
import SignUpPage from './pages/SignUpPage/SignUpPage';
import EvaluateResultPage from './pages/EvaluateResultPage/EvaluateResultPage';
import CandidatePage from './pages/CandiateTablePage/CandidatePage';

function App() {
  return (
    <Router>
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/form" element={<FormPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/evaluate-result" element={< EvaluateResultPage />} />
        <Route path="/candidate" element={<CandidatePage />} />
      </Routes>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;

