import React from 'react';
import './Footer.css'
import logo from '../../assets/images/logo.png';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <img src={logo} alt="Logo" className="footer-logo" />
        <p>© 2024 Innovators of Tomorrow. All rights reserved</p>
      </div>
    </footer>
  );
}

export default Footer;
