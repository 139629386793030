import React from 'react';
import './ImpactSection.css'

function ImpactSection() {
    return (
        <section className="impact">
        <h2>Our Impact in Numbers</h2>
        <p>Innovators of Tomorrow is making a measurable difference in the internship hiring process. Join us and be part of this success story.</p>
        <div className="impact-stats">
            <div className="stat">
            <h3>1 Week</h3>
            <p>Time to Hire an Intern</p>
            </div>
            <div className="stat">
            <h3>95%</h3>
            <p>Match Success Rate</p>
            </div>
            <div className="stat">
            <h3>1k+</h3>
            <p>Pre-Vetted Intern Profiles</p>
            </div>
        </div>
        <div className="cta-section">
            <div className="cta-content">
            <h3>Join Our Talent Pool Today</h3>
            <p>Create your profile and connect with top companies looking for interns like you.</p>
            </div>
            <button className="cta-button">Get started</button>
        </div>
        </section>
    );
}

export default ImpactSection;
