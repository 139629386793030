import React from 'react';
import './HeroSection.css'
import hero from '../../assets/images/heroPage.png'

function HeroSection() {
  return (
    <section className="hero">
      <div className="hero-content">
        <h1>Connecting Tomorrow’s Innovators with Today’s Opportunities</h1>
        <p>Discover, hire, and nurture talent through a platform dedicated to meaningful connections...</p>
        <div className="hero-buttons">
          <button className="find-intern">Find Your Perfect Intern</button>
          {/* <button className="join-talent-pool">Join Our Talent Pool</button> */}
          <a href="https://airtable.com/apprgm9uM3iYYYC1z/pagBqhJViVOJNFPX2/form" target="_blank" rel="noopener noreferrer">
            <button className="join-talent-pool">Join Our Talent Pool</button>
          </a>
        </div>
      </div>
      <div className="hero-image">
        <img src={hero} alt="Students" />
      </div>
    </section>
  );
}

export default HeroSection;
