import React from 'react';
import Header from '../Header/Header';
import HeroSection from '../HeroSection/HeroSection';
import MissionSection from '../MissionSection/MissionSection';
import ImpactSection from '../ImpactSection/ImpactSection';
import Footer from '../Footer/Footer';
import SupportSection from '../SupportSection/SupportSection';

function HomePage() {
  return (
    <div>
        <Header/>
        <HeroSection />
        <MissionSection />
        <ImpactSection />
        <SupportSection />
        <Footer/>
    </div>
  );
}

export default HomePage;
