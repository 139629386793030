import React from 'react';
import { motion } from 'framer-motion';
import './MissionSection.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome CSS
import companyIcon from '../../assets/images/companyIcon.png';
import mission from '../../assets/images/missionPage.png';
import studentIcon from '../../assets/images/studentIcon.png';
import studentsImage from '../../assets/images/studentPage.png';

function MissionSection() {
  return (
    <section className="mission">
      <motion.h2
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Our Mission
      </motion.h2>
      <motion.p
        className="mission-description"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        Empowering the next generation of innovators by bridging the gap between talent and opportunity.
      </motion.p>
      <div className="mission-content">
        <motion.div
          className="mission-text"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="mission-icon">
            <img src={companyIcon} alt="For Companies" />
          </div>
          <h3>For Companies</h3>
          <p className="main-text">Simplify Your Internship Hiring Process.</p>
          <p className="sub-text">Discover, hire, and nurture top talent effortlessly with our intern matching and management community, making the process smooth and straightforward.</p>
          <ul>
            <li><i className="fas fa-check-circle"></i> Cut intern search time from 3 months to 1 week.</li>
            <li><i className="fas fa-check-circle"></i> Access thousands of pre-vetted candidates.</li>
            <li><i className="fas fa-check-circle"></i> Automated interview scheduling.</li>
          </ul>
        </motion.div>
        <motion.div
          className="mission-image"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
        >
          <img src={mission} alt="Mission" />
        </motion.div>
      </div>

      <div className="students-content">
        <motion.div
          className="students-image"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <img src={studentsImage} alt="Students" />
        </motion.div>
        <motion.div
          className="students-text"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="students-icon">
            <img src={studentIcon} alt="For Students" />
          </div>
          <h3>For Students</h3>
          <p className="students-description">One Profile, Endless Opportunities.</p>
          <p className="students-sub-description">Join our platform and unlock access to numerous internship opportunities with leading companies. Simplify your search and kickstart your career.</p>
          <ul>
            <li><i className="fas fa-check-circle"></i> Fill out one application to get discovered by top companies.</li>
            <li><i className="fas fa-check-circle"></i> Receive direct interview invites.</li>
            <li><i className="fas fa-check-circle"></i> Track application status in real-time.</li>
          </ul>
        </motion.div>
      </div>
    </section>
  );
}

export default MissionSection;
