import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../../assets/images/logo.png';

function Header() {
  return (
    <header className="header">
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        {/* <nav>
          <ul className="nav-links">
            <li><a href="#for-companies">For Companies</a></li>
            <li><a href="#for-students">For Students</a></li>
          </ul>
        </nav> */}
        <div className="auth-buttons">
          <Link to="/login" className="login-button">Log in</Link>
          <Link to="/signup" className="signup-button">Sign up</Link>
        </div>
      </div>
    </header>
  );
}

export default Header;
