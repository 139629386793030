import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/navigateBackHeader";
import "./FormPage.css";

function FormPage() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    availability: "",
    location: "",
    compensation: "",
    availabilityType: "",
    interview_link: "",
    position: "",
    job_description: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formValues.job_description) {
      tempErrors.job_description = "Job description is required";
    } 
    else if (formValues.job_description.length < 200) {
      tempErrors.job_description =
        "Job description must be at least 200 characters";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const token = localStorage.getItem("authToken");
  console.log("Token being used:", token);


const pollForResults = async (jobId) => {
  const pollInterval = 300000; //  5 minutes
  const maxAttempts = 50; // Will poll for a maximum of 3 minutes
  let attempts = 0;
  const poll = async () => {
    attempts++;
    try {
      const response = await fetch(`https://resume.innovatorsoftomorrow.org/api/search/evaluate`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const resultData = await response.json();
      if (response.ok) {
        navigate("/candidate", {
          state: { candidates: resultData.best_candidates, formValues },
        });
      } else if (attempts < maxAttempts) {
        setTimeout(poll, pollInterval); // Retry after the interval
      } else {
        setErrors({ api: "The process is taking longer than expected. Please try again later." });
      }
    } catch (error) {
      setErrors({ api: "A network error occurred while polling. Please try again later." });
    }
  };
  poll(); // Start polling
};

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) return;
    setIsLoading(true);

    try {
	console.log("Inside log of Formpage.js");
  	const controller = new AbortController();
  	//const timeoutId = setTimeout(() => controller.abort(), 300000); // Set timeout to 30 seconds (30000 ms)
        const timeoutId = setTimeout(() => {
    		controller.abort();
    		console.error("Request timed out!"); // Log the timeout error
  	}, 300000); // Set timeout to 300 seconds (300,000 ms)


      const response = await fetch(
        "https://resume.innovatorsoftomorrow.org/api/search/evaluate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
	    Connection: "keep-alive",
          },
          body: JSON.stringify(formValues),
	  signal: controller.signal,
        }
      );

  // Clear the timeout if the request completes successfully
  clearTimeout(timeoutId);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }


      const data = await response.json();
      console.log("Data from API:", data); // Debugging line
      if (response.ok) {
        console.log({ token });
	console.log("Inside response .ok");
	const  candidateId  =  data.best_candidates;
//	pollForResults(candidateId);
	navigate("/candidate", {
          state: { candidates: data.best_candidates, formValues },
        });
      } else {
        setErrors({
          api:
            data.message || "An error occurred while processing your request.",
        });
      }
    } catch (error) {
      setErrors({ api: "A network error occurred. Please try again later." });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken"); // Remove token from localStorage
    console.log("Token removed:", token);
    navigate("/login"); // Redirect to login page
  };

  return (
    <div>
      <Header />
      <div className="form-page">
        <div className="form-container">
          <h2>Find Your Best Intern</h2>
          {errors.api && <p className="error-message">{errors.api}</p>}
          <form onSubmit={handleSubmit}>
            <label>Availability</label>
            <select
              name="availability"
              value={formValues.availability}
              onChange={handleChange}
            >
              <option value="Summer 2024">Summer 2024</option>
              <option value="Fall 2024">Fall 2024</option>
              <option value="Spring 2025">Spring 2025</option>
            </select>
            <label>Location</label>
            <select
              name="location"
              value={formValues.location}
              onChange={handleChange}
            >
              <option value="Remote">Remote</option>
              <option value="Onsite">Onsite</option>
              <option value="Both">Both</option>
              
            </select>
            <label>Preferred Compensation</label>
            <select
              name="compensation"
              value={formValues.compensation}
              onChange={handleChange}
            >
              <option value="Paid">Paid</option>
              <option value="Unpaid">Unpaid</option>
              
            </select>
            <label>Availability Type</label>
            <select
              name="availabilityType"
              value={formValues.availabilityType}
              onChange={handleChange}
            >
              <option value="Full-time">Full Time</option>
              <option value="Part-time">Part Time</option>
            </select>
            <label>Interview Link</label>
            <input
              type="text"
              name="interview_link"
              placeholder="e.g. www.calendly.com/a12345"
              value={formValues.interview_link}
              onChange={handleChange}
            />
            <label>Position</label>
            <input
              type="text"
              name="position"
              placeholder="e.g., Software Engineer Intern"
              value={formValues.position}
              onChange={handleChange}
            />
            <label>Job Description</label>
            <textarea
              name="job_description"
              placeholder="Describe the responsibilities and requirements for this position"
              value={formValues.job_description}
              onChange={handleChange}
              rows={4}
            />
            {errors.job_description && (
              <p className="error-message">{errors.job_description}</p>
            )}
            <button type="submit" className="form-button" disabled={isLoading}>
              {isLoading ? "Loading..." : "Search top candidates"}
            </button>
          </form>
          <button className="logout-button-form" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}

export default FormPage;
